import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import ButtonBase from '@mui/material/ButtonBase';

export default function ToggleSwitch({ onChange, toggle1Label, toggle1Value, toggle2Label, toggle2Value, value }) {
  const [indicatorStyle, setIndicatorStyle] = useState();
  const wrapperRef = useRef();
  const toggle1Ref = useRef();
  const toggle2Ref = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const activeRef = toggle1Value === value ? toggle1Ref : toggle2Value === value && toggle2Ref;
    const newIndicatorStyle = {};
    if (!activeRef) {
      newIndicatorStyle.left = 0;
      newIndicatorStyle.width = 0;
    } else {
      const rect = activeRef.current.getBoundingClientRect();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const correction = 1; // for border width
      newIndicatorStyle.left = rect.left - wrapperRect.left - correction;
      newIndicatorStyle.width = rect.width;
    }
    if (
      isNaN(indicatorStyle?.width) ||
      isNaN(indicatorStyle?.left) ||
      Math.abs(indicatorStyle.width - newIndicatorStyle.width) > 1 ||
      Math.abs(indicatorStyle.left - newIndicatorStyle.left) > 1
    ) {
      setIndicatorStyle({ left: newIndicatorStyle.left, width: newIndicatorStyle.width });
    }
  });
  return (
    <div
      css={({ palette, shape: { borderRadius }, spacing, transitions, typography }) => ({
        display: 'inline-flex',
        border: `1px solid ${palette.primary.main}`,
        position: 'relative',
        borderRadius,
        backgroundColor: palette.background.paper1,
        '& > .toggleBtn': {
          whiteSpace: 'pre',
          padding: spacing(1.5, 2),
          borderRadius,
          ...typography.overline,
          color: palette.primary.main,
          transition: transitions.create(['color'], { duration: 300 }),
          '&.toggleBtn_active': {
            // backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
        '& > .indicator': {
          position: 'absolute',
          height: '100%',
          borderRadius,
          backgroundColor: palette.primary.main,
          transition: transitions.create(['left', 'width'], { duration: 300 }),
        },
      })}
      ref={wrapperRef}
    >
      {indicatorStyle && <div className="indicator" style={indicatorStyle} />}
      <ButtonBase
        className={classNames('toggleBtn', { toggleBtn_active: value === toggle1Value })}
        onClick={() => onChange(toggle1Value)}
        ref={toggle1Ref}
      >
        {toggle1Label}
      </ButtonBase>
      <ButtonBase
        className={classNames('toggleBtn', { toggleBtn_active: value === toggle2Value })}
        onClick={() => onChange(toggle2Value)}
        ref={toggle2Ref}
      >
        {toggle2Label}
      </ButtonBase>
    </div>
  );
}

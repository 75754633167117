import standardApiRequestAction from 'my-core/standardApiRequestAction';

export function verifyDiscountCode(query, productType, productId) {
  return standardApiRequestAction({
    path: '/api/v1/discount_codes/verify',
    body: { query, product_type: productType, product_id: productId },
  });
}

export function fetchDiscountCodes(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/discount_codes',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function createDiscountCode(params) {
  return standardApiRequestAction({
    path: '/api/v1/discount_codes',
    method: 'post',
    body: { discount_code: params },
  });
}

export function updateDiscountCode(discountCodeId, params) {
  return standardApiRequestAction({
    path: `/api/v1/discount_codes/${discountCodeId}`,
    method: 'patch',
    body: { discount_code: params },
  });
}

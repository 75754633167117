import { PAID_PRODUCTS, SUBSCRIPTION_PRODUCT_TYPES_CONFIG } from 'my-core/subscription-utils';
import { numberToCurrency } from 'my-utils';

import CustomColorButton from 'my-elements/CustomColorButton';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SubscriptionCheckoutProductOptions({ onSelectProduct, plan, product }) {
  const isMonthlyBilling = plan === 'monthly';
  return (
    <div
      css={({ palette, shape: { borderRadius }, spacing, typography }) => ({
        marginTop: spacing(2),
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        gap: spacing(2),
        '& > .planItem': {
          flex: '1 0 0',
          display: 'block',
          minWidth: 200,
          maxWidth: 440,
          borderRadius,
          overflow: 'hidden',
          textAlign: 'center',
          borderStyle: 'solid',
          borderWidth: 1,
          '& > .overline': {
            ...typography.overline,
            padding: spacing(1),
          },
          '& > .planItemContent': {
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(2),
            padding: spacing(2),
            '& .planPerks': {
              textAlign: 'left',
              '& > div': {
                display: 'flex',
                gap: spacing(1),
                '& > svg': { marginTop: '0.25em', color: palette.text.secondary },
                '& + div': { marginTop: spacing(1) },
              },
            },
          },
        },
      })}
    >
      {PAID_PRODUCTS.map(prod => {
        const productConfig = SUBSCRIPTION_PRODUCT_TYPES_CONFIG[prod];
        const selected = prod === product;

        return (
          <ButtonBase
            key={prod}
            className="planItem"
            component="div"
            css={({ palette }) => ({
              borderColor: palette[productConfig.color].main,
              boxShadow: selected && `0 0 0 1px ${palette[productConfig.color].main}`,
              '& > .overline': {
                backgroundColor: palette[productConfig.color].background,
                color: palette[productConfig.color].main,
              },
            })}
            onClick={() => onSelectProduct(prod)}
          >
            <div className="overline">{productConfig.overline}</div>
            <div className="planItemContent">
              <div>
                <Typography variant="h6">{productConfig.label}</Typography>
                <Typography>{productConfig.subtitle}</Typography>
              </div>
              <div>
                <Typography sx={{ '& > span': { typography: 'caption', color: 'text.secondary' } }} variant="h6">
                  {isMonthlyBilling ?
                    numberToCurrency(productConfig.pricing[plan])
                  : <>
                      {`${numberToCurrency(productConfig.pricing.yearly / 12)} `}
                      <span>/ month</span>
                    </>
                  }
                </Typography>
                <Typography sx={{ color: 'text.secondary', '& > span': { color: 'success.main' } }} variant="body2">
                  {isMonthlyBilling ?
                    'per month'
                  : <>
                      {`${numberToCurrency(productConfig.pricing.yearly)} paid yearly `}
                      <span>{`${numberToCurrency(productConfig.pricing.monthly * 12 - productConfig.pricing.yearly, { hideCents: true })} off`}</span>
                    </>
                  }
                </Typography>
              </div>
              <CustomColorButton
                color={productConfig.color}
                fullWidth
                onClick={() => onSelectProduct(prod)}
                startIcon={selected && <FontAwesomeIcon icon={faCheck} />}
                variant={selected ? 'contained' : 'outlined'}
              >
                {selected ? 'Selected' : `Select ${productConfig.label}`}
              </CustomColorButton>
              <div className="planPerks">
                {productConfig.perks.map(perk => (
                  <div key={perk}>
                    <FontAwesomeIcon icon={faCheck} />
                    <Typography variant="body2">{perk}</Typography>
                  </div>
                ))}
              </div>
            </div>
          </ButtonBase>
        );
      })}
    </div>
  );
}

import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useStandardApiRequest } from 'my-core/hooks';

import DiscountCodeInput from './DiscountCodeInput';

import { verifyDiscountCode } from 'my-actions/DiscountCodeActions';

function DiscountCodeInputCtnr(props) {
  const { onVerified, productId, productType, ...rest } = props;

  const { performRequest: performVerify, requestStatus: verifyStatus } = useStandardApiRequest({
    actionCreator: verifyDiscountCode,
    errorMessage: true,
  });
  const verifyCode = useCallback(
    query => {
      if (!query) return;
      performVerify(query, productType, productId);
    },
    [performVerify, productId, productType],
  );

  const discountCode = useSelector(state => state.discountCodes.items[verifyStatus?.id]);
  useEffect(() => {
    if (!verifyStatus || verifyStatus.fetching) return;
    onVerified(discountCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyStatus]);

  return <DiscountCodeInput onVerified={onVerified} onVerify={verifyCode} verifyStatus={verifyStatus} {...rest} />;
}

DiscountCodeInputCtnr.propTypes = {
  className: PropTypes.string,
  ButtonProps: PropTypes.object,
  defaultQuery: PropTypes.string,
  disabled: PropTypes.bool,
  onVerified: PropTypes.func.isRequired,
  productId: PropTypes.any,
  productType: PropTypes.string.isRequired,
  TextFieldProps: PropTypes.object,
};

export default DiscountCodeInputCtnr;

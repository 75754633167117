import { useMemo, useReducer } from 'react';

const initState = { open: false };

function reducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return { open: true, payload: action.payload };
    case 'CLOSE':
      return { ...state, open: false };
    case 'CLEAR':
      return initState;
  }
}

export default function useDialogStateManager(initialState = initState) {
  const [{ open, payload }, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      open(payload) {
        dispatch({ type: 'OPEN', payload });
      },
      close() {
        dispatch({ type: 'CLOSE' });
      },
      clear() {
        dispatch({ type: 'CLEAR' });
      },
    }),
    [],
  );

  return [open, payload, actions];
}

export function calculateDiscount(discountCode, price) {
  if (!discountCode) return 0;
  if (discountCode.subscription_trial) return price;
  if (discountCode.discount_type === 'percentage') return Math.round(price * discountCode.amount) / 100;
  else return Math.min(price, discountCode.amount);
}

export const DISCOUNT_TYPES = {
  percentage: {
    label: 'Percentage',
    description: 'Discount a percentage of total value',
  },
  fixed: {
    label: 'Fixed',
    description: 'Discount a fixed dollar amount',
  },
};

export const USAGE_TYPES = {
  unlimited: {
    label: 'Unlimited usage',
    description: '',
  },
  once_per_user: {
    label: 'Once per user',
    description: '',
  },
  one_time: {
    label: 'Only used 1 time',
    description: 'This discount code can only be used for a singe purchase',
  },
};

export const DURATION_TYPES = {
  once: { label: 'Once', description: '' },
  repeating: { label: 'Multiple Months', description: '' },
  forever: { label: 'Forever', description: '' },
};

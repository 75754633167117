import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette, spacing, typography }) => ({
    root: { display: 'flex' },
    button: { marginLeft: spacing(0.5) },
    input_active: {
      fontWeight: typography.fontWeightExtraBold,
      textTransform: 'uppercase',
      letterSpacing: 2,
    },
    focused: {},
    notchedOutline: {},
    inputRoot: {},
    inputRoot_active: {
      color: palette.primary.main,
      '&:hover $notchedOutline': { borderColor: palette.primary.main },
    },
    inputRoot_error: {
      color: palette.secondary.main,
      '&$focused $notchedOutline, &:hover $notchedOutline': { borderColor: palette.secondary.main },
    },
  }),
  { name: 'DiscountCodeInput' },
);

export default function DiscountCodeInput(props) {
  const {
    ButtonProps,
    className,
    defaultQuery,
    disabled,
    onVerified,
    onVerify,
    TextFieldProps,
    verifyDefaultQuery,
    verifyStatus,
  } = props;
  const classes = useStyles(props);

  const [isValidCode, setIsValidCode] = useState(null);
  useEffect(() => {
    if (verifyStatus && !verifyStatus?.fetching) setIsValidCode(!!verifyStatus?.success);
  }, [verifyStatus]);

  const [query, setQuery] = useState(defaultQuery || '');
  useEffect(() => {
    if (defaultQuery) setQuery(query => query || defaultQuery);
  }, [defaultQuery]);
  const updateQuery = useCallback(
    e => {
      if (isValidCode) onVerified(null);
      setIsValidCode(null);
      setQuery(e?.target?.value || '');
    },
    [isValidCode, onVerified],
  );

  const queryEqualsDefault = defaultQuery === query;
  useEffect(() => {
    if (!(verifyDefaultQuery && defaultQuery && queryEqualsDefault)) return;
    onVerify(defaultQuery);
  }, [verifyDefaultQuery, onVerify, defaultQuery, queryEqualsDefault]);

  return (
    <form
      className={classNames(className, classes.root)}
      onSubmit={e => {
        e.preventDefault();
        onVerify(query);
      }}
    >
      <TextField
        disabled={disabled || !!verifyStatus?.fetching}
        fullWidth
        InputProps={{
          classes: {
            input: classNames({ [classes.input_active]: query }),
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            root: classNames(classes.inputRoot, {
              [classes.inputRoot_active]: query,
              [classes.inputRoot_error]: isValidCode === false,
            }),
          },
          endAdornment: isValidCode !== null && (
            <InputAdornment position="end">
              {isValidCode ?
                <FontAwesomeIcon icon={faCheck} />
              : <IconButton color="secondary" onClick={() => updateQuery()} size="small">
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              }
            </InputAdornment>
          ),
        }}
        margin="none"
        onChange={updateQuery}
        placeholder="Add Promo Code"
        size="small"
        value={query}
        {...TextFieldProps}
      />
      {isValidCode === null && (
        <Button
          className={classes.button}
          disabled={disabled || !!verifyStatus?.fetching}
          size="medium"
          type="submit"
          variant="contained"
          {...ButtonProps}
        >
          Apply
        </Button>
      )}
    </form>
  );
}

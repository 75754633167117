import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { faLockAlt, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ breakpoints, spacing }) => ({
    cardFields: { display: 'flex', flexDirection: 'column', padding: spacing(2) },
    cardFieldsInline: {
      display: 'flex',
      '& > * + *': { marginLeft: spacing(1.5) },
    },
    [breakpoints.only('xs')]: {
      cardFieldsInline: {
        flexDirection: 'column',
        '& > * + *': { marginLeft: 0 },
      },
    },
  }),
  { name: 'PaymentFormCardFields' },
);

function PaymentFormCardFields(props) {
  const {
    billingDetails,
    disableFields,
    elements,
    onChangeBillingDetails,
    onChangeCardType,
    onChangeValidationErrors,
    stripeElementRef,
    validationErrors,
  } = props;
  const classes = useStyles(props);
  const {
    palette: {
      text: { primary: themeTextColor },
    },
    typography: { fontFamily: themeFontFamily },
  } = useTheme();
  const [numberLabelShrink, setNumberLabelShrink] = useState(false);
  const [expiryLabelShrink, setExpiryLabelShrink] = useState(false);
  const [cvcLabelShrink, setCvcLabelShrink] = useState(false);

  const cardNumberRef = useRef();
  const cardExpiryRef = useRef();
  const cardCvcRef = useRef();

  useEffect(() => {
    if (elements) {
      const commonOptions = {
        placeholder: '',
        style: {
          base: {
            fontSize: '16px',
            color: themeTextColor,
            fontFamily: themeFontFamily,
            fontSmoothing: 'antialiased',
          },
        },
      };
      const number = elements.create('cardNumber', commonOptions);
      const expiry = elements.create('cardExpiry', commonOptions);
      const cvc = elements.create('cardCvc', commonOptions);

      stripeElementRef.current = number;

      number.mount(cardNumberRef.current);
      expiry.mount(cardExpiryRef.current);
      cvc.mount(cardCvcRef.current);

      number.on('change', e => {
        if (e.error) return onChangeValidationErrors('number', e.error.message);
        onChangeCardType(e.brand);
        onChangeValidationErrors('number', false);
      });
      expiry.on('change', e => onChangeValidationErrors('expiry', e.error?.message || false));
      cvc.on('change', e => onChangeValidationErrors('cvc', e.error?.message || false));

      [
        [number, setNumberLabelShrink],
        [expiry, setExpiryLabelShrink],
        [cvc, setCvcLabelShrink],
      ].forEach(([node, setter]) => {
        node.on('focus', () => setter(true));
        node.on('blur', () => setter(!node._empty));
      });

      return () => {
        number.destroy();
        expiry.destroy();
        cvc.destroy();
        stripeElementRef.current = null;
      };
    }
  }, [elements, onChangeCardType, onChangeValidationErrors, stripeElementRef, themeTextColor, themeFontFamily]);

  const commonFieldProps = {
    disabled: disableFields,
    fullWidth: true,
    margin: 'dense',
    size: 'small',
    required: true,
    FormHelperTextProps: { style: { color: 'red' } },
  };
  return (
    <form className={classes.cardFields}>
      <TextField
        {...commonFieldProps}
        autoComplete="cc-name"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <FontAwesomeIcon icon={faLockAlt} />
            </InputAdornment>
          ),
        }}
        label="Name on Card"
        name="ccname"
        onChange={e => onChangeBillingDetails(v => ({ ...v, name: e.target.value }))}
        value={billingDetails?.name || ''}
      />
      <TextField
        {...commonFieldProps}
        autoComplete="cc-number"
        helperText={<span>{validationErrors?.number || ''}</span>}
        InputLabelProps={{ shrink: numberLabelShrink }}
        InputProps={{ inputComponent: 'div' }}
        inputRef={cardNumberRef}
        label="Card Number"
        name="cardnumber"
      />
      <div className={classes.cardFieldsInline}>
        <TextField
          {...commonFieldProps}
          autoComplete="cc-exp"
          helperText={<span>{validationErrors?.expiry || ''}</span>}
          InputLabelProps={{ shrink: expiryLabelShrink }}
          InputProps={{ inputComponent: 'div' }}
          inputRef={cardExpiryRef}
          label="Expiry MM/YY"
          name="ccexp"
        />
        <TextField
          {...commonFieldProps}
          autoComplete="cc-csc"
          helperText={<span>{validationErrors?.cvc || ''}</span>}
          InputLabelProps={{ shrink: cvcLabelShrink }}
          InputProps={{
            inputComponent: 'div',
            endAdornment: (
              <Tooltip placement="top-end" title="This is the security code found on the back of your credit card">
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </InputAdornment>
              </Tooltip>
            ),
          }}
          inputRef={cardCvcRef}
          label="CVC"
          name="cccsc"
        />
        <TextField
          {...commonFieldProps}
          autoComplete="postal-code"
          label="Postal Code"
          name="postalcode"
          onChange={e => onChangeBillingDetails(v => ({ ...v, postal_code: e.target.value }))}
          value={billingDetails?.postal_code || ''}
        />
      </div>
    </form>
  );
}

export default PaymentFormCardFields;

import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { COLOR_NAMES } from 'my-theme/myTheme';

import Button from '@mui/material/Button';

const useStyles = makeStyles(
  ({ palette }) => {
    const [dark, darkHover] =
      palette.mode === 'dark' ? [palette.grey[50], palette.grey[200]] : [palette.grey[900], palette.grey[800]];
    return {
      color_black: {
        '&.MuiButton-contained:not(.Mui-disabled)': {
          backgroundColor: dark,
          color: palette.background.paper,
          '&:hover': { backgroundColor: darkHover },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': { borderColor: dark, color: dark },
        '&.MuiButton-text:not(.Mui-disabled)': { color: dark },
      },
      color_white: {
        '&.MuiButton-contained:not(.Mui-disabled)': {
          backgroundColor: palette.common.white,
          color: palette.grey[900],
          '&:hover': { backgroundColor: palette.grey[100] },
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': { borderColor: palette.common.white, color: palette.common.white },
        '&.MuiButton-text:not(.Mui-disabled)': { color: palette.common.white },
      },
      ...palette.mapColors(c => ({
        [`color_${c}`]: {
          '&.MuiButton-contained:not(.Mui-disabled)': {
            backgroundColor: palette[c].main,
            color: palette[c].contrastText,
            '&:hover': { backgroundColor: palette[c].dark },
          },
          '&.MuiButton-outlined:not(.Mui-disabled)': { borderColor: palette[c].main, color: palette[c].main },
          '&.MuiButton-text:not(.Mui-disabled)': { color: palette[c].main },
        },
        [`color_white_${c}`]: {
          '&.MuiButton-contained:not(.Mui-disabled)': {
            backgroundColor: palette.common.white,
            color: palette[c].main,
            '&:hover': { backgroundColor: palette.grey[100] },
          },
          '&.MuiButton-outlined:not(.Mui-disabled)': { borderColor: palette.common.white, color: palette[c].main },
          '&.MuiButton-text:not(.Mui-disabled)': { color: palette.common.white },
        },
        [`color_${c}_medium`]: {
          '&.MuiButton-contained:not(.Mui-disabled)': {
            backgroundColor: palette[c].medium,
            color: palette.common.white,
            '&:hover': { backgroundColor: palette[c].dark },
          },
          '&.MuiButton-outlined:not(.Mui-disabled)': { borderColor: palette[c].medium, color: palette[c].medium },
          '&.MuiButton-text:not(.Mui-disabled)': { color: palette[c].medium },
        },
      })),
    };
  },
  { name: 'CustomColorButton' },
);

export default function CustomColorButton(props) {
  const { className, color, colorKey = 'main', ...rest } = props;
  const classes = useStyles(props);

  // 'main' color key has no suffix, but other color keys do
  const colorClass = classes[colorKey === 'main' ? `color_${color}` : `color_${color}_${colorKey}`];

  return <Button {...rest} className={classNames(className, colorClass)} />;
}

CustomColorButton.propTypes = {
  color: PropTypes.oneOf([...COLOR_NAMES, ...COLOR_NAMES.map(c => `white_${c}`), 'black', 'white']).isRequired,
  colorKey: PropTypes.oneOf(['main', 'medium']),
};

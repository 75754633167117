import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { getMessageFromError } from 'my-core/error-utils';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(
  ({ constants, spacing }) => ({
    root: {},
    rootFullPage: { padding: spacing(constants.APPBAR_HEIGHT_UNITS + 2, 2, 2) },
  }),
  { name: 'ErrorMessage' },
);

export default forwardRef(function ErrorMessage(props, ref) {
  const { className, error, fullPage, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Typography
      className={classNames(className, classes.root, { [classes.rootFullPage]: fullPage })}
      {...rest}
      ref={ref}
    >
      {getMessageFromError(error)}
    </Typography>
  );
});

import { useEffect } from 'react';

import { insertScript, loadedScripts } from 'my-core/script-utils';

function useLoadScript(scriptSrc, options) {
  useEffect(() => {
    const off = insertScript(scriptSrc, options);
    return off; // so callbacks disabled on unmount
  }, [options, scriptSrc]);
}

useLoadScript.loadedScripts = loadedScripts;
export default useLoadScript;
